<template>
  <section id="hero-pro">
    <base-img
      :min-height="minHeight"
      :src="getBackgroundPicture()"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading
            size="text-h2"
            title="KIDIWI Services"
            weight="medium"
          />
          <base-subheading
            weight="regular"
            :title="$t('heroPro.line1')"
          />
          <!--  <p>{{ $t('topmenu.professional') }}</p> -->
          <p>{{ $t('heroPro.line2') }}</p>
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <kdw-btn
              id="discover"
              outlined
              color="white"
              href="#Discover"
            >
              {{ $t('button.moreInfo') }}
            </kdw-btn>

            <span
              class="font-weight-bold ml-6 mr-6 my-4"
            >
              {{ $t('heroPro.or') }}
            </span>

            <kdw-btn
              id="accountCreation"
              @click="openConnexion()"
            >
              {{ $t('button.createAccountInfo') }}
            </kdw-btn>
          </div>
        </v-responsive>
      </v-container>
    </base-img>
  </section>
</template>

<script>
  import { navigate } from '@/helpers/Navigation'
  import { getBackgroundPicture } from '@/helpers/Background'

  export default {
    name: 'SectionHeroPro',

    provide: {
      theme: { isDark: false },
    },

    data: () => {
      return {
        backgroundPictureReference: '',
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

    methods: {
      openConnexion () {
        navigate(this, 'PLogin')
      },
      getBackgroundPicture () {
        if (this.backgroundPictureReference !== '') return this.backgroundPictureReference
        this.backgroundPictureReference = getBackgroundPicture()
        return this.backgroundPictureReference
      },
    },
  }

</script>
